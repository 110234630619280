.App {
  text-align: left;
  color: white;
  background-color: #282c34;
}

.App-logo {

    width: 20%;
    height: auto;

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: inline;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
img {
  border: 0px
}

h2 {
  margin-left: 0px;
  margin-bottom: 0%;
}
h5 {
  padding: 0;
  padding-top: 0%;
  padding-bottom: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  font-weight: normal;
}

li {
  color: gray;
  font-weight: 400;
  font-size: medium;
  list-style-type: none;
  padding: 0px;
  text-align: left;
}



.li1 {
  color: lightgray;
  font-weight: 400;
  font-size: medium;
  list-style-type: none;
  padding-left: 3px;
  
}
.ul1 {
  color: lightgray;
  padding-left: 5px;
  font-size: small;
}

table {
  overflow-x:auto;
  border-collapse: collapse;
  margin-left: 5px;
  
}

th {
  border-bottom: 0px solid black;
  border: 0px solid whitesmoke;
  
}

td {
  vertical-align: top;
  text-align: left;
  border: 0px solid darkgray;
  border-top: 0px solid darkgray;
  border-bottom: 0px solid darkgray;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 0px;
}

.td1 {
  vertical-align: top;
  text-align: left;
  border: 1px solid black;
  
  
}
.td2 {
  vertical-align: top;
  text-align: left;
  border: 0px solid black;
  border-bottom: 0px solid black;
  padding: 0%;
  width: 600px;
  
}
.td3 {
  vertical-align: top;
  text-align: left;
  border: 0px dashed darkgray;
  padding: 0%;
  font-weight: normal;
  border-top: 1px dashed darkgray;
  padding-top: 3px;
  padding-left: 0px;
}


.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  
}

.root h1 {
  margin-bottom: 1.6rem;
  font-size: 24px;
  text-align: center;
}
.MuiListItemText-secondary {
  color: white;
}

.DeleteIcon {
  color:white;
}
.IconButton {
  color: white;

}

.MuiListItemText-dense {
  /* Custom styles for dense ListItemText */
  color: yellow; /* Example color */
  font-weight: bolder;
  text-align: left;
  font-size: 0.875rem; /* Smaller font size for dense list items */
 
  
  
}
